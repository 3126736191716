import axios from "axios";

import { marcaciones_por_dni, marcaciones_x_dependencia, detalle_marcaciones_rango, marcaciones_rango, crear_marcacion_remota, restaurar_mensajes, restaurar_dependencias, limpiar_marcaciones, prestaciones_red, limpiar_prestaciones } from '../reducers/marcacionesReducer';

import { setAuthorizationInterceptor } from '../../utils/tokenAuthorization';

setAuthorizationInterceptor();

export const marcaciones_por_dependencia = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/marcaciones/dependencia`, data)
      .then(response => {
        dispatch(marcaciones_x_dependencia(
          {
            marcaciones: response.data.marcaciones,
            horarios: response.data.horarios,
            resumen: response.data.resumen,
            has_next: response.data.has_next,
            has_prev: response.data.has_prev,
            total: response.data.total,
            status: response.data.status,
            text: response.data.text,
          }
        ))
      })
      .catch(error => console.log(error));
  };
};

export const marcaciones_dni = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/marcaciones/dni`, data)
      .then(response => {
        dispatch(marcaciones_por_dni(
          {
            data: response.data.marcaciones,
            inicio: data.inicio,
            fin: data.fin,
            usuario_horario: response.data.usuario_horario,
            sereno: response.data.sereno,
            horas: response.data.horas,
            status: response.data.status,
            text: response.data.text,
          }
        ));
      })
      .catch(error => console.log(error));
  };
};

export const marcaciones_dni_actual = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/marcaciones/actual/dni`, data)
      .then(response => {
        dispatch(marcaciones_por_dni(
          {
            data: response.data.marcaciones,
            inicio: data.inicio,
            fin: data.fin,
            usuario_horario: response.data.usuario_horario,
            sereno: response.data.sereno,
            horas: response.data.horas,
            status: response.data.status,
            text: response.data.text,
          }
        ));
      })
      .catch(error => console.log(error));
  };
};

export const detalle_marcaciones = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/marcaciones/dni/detalle`, data)
      .then(response => {
        dispatch(detalle_marcaciones_rango(
          {
            detalle: response.data.detalle,
          }
        ))
      })
      .catch(error => console.log(error));
  }
}

export const prestaciones = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/marcaciones/prestaciones`, data)
      .then(response => {
        dispatch(prestaciones_red(
          {
            tardanzas: response.data.tardanzas,
            huella_unica: response.data.huella_unica,
            ausentes_por_tardanza: response.data.ausentes_por_tardanza,
            ingresos_anticipados: response.data.ingresos_anticipados,
            carga_horaria_incumplida: response.data.carga_horaria_incumplida
          }
        ))
      })
      .catch(error => console.log(error));
  }
}

export const marcaciones_por_rango = (fecha) => {
  return function(dispatch) {
    dispatch(marcaciones_rango({ inicio: fecha.inicio, fin: fecha.fin }));
  };
};

export const descargar_marcaciones_xlsx = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/marcaciones/download-xlsx`, data, {
      responseType: "arraybuffer"
    })
      .then(response => {

        let filename = response.headers['x-filename'];

        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.target = "_blank";
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.log(error));
  };
};

export const descargar_marcaciones_PDF = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/marcaciones/download-PDF`, data, {
      responseType: "blob",
    })
      .then(response => {
        let filename = response.headers['x-filename'];
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.log(error));
    };
};

export const descargar_reporte_saf = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/marcaciones/reporte-saf`, data, {
      responseType: "blob",
      timeout: 1200000
    })
      .then(response => {
        let filename = response.headers['x-filename'];
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.log(error));
    };
};

export const descargar_prestaciones_xlsx = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/marcaciones/prestaciones-xlsx`, data, {
      responseType: "arraybuffer"
    })
      .then(response => {

        let filename = response.headers['x-filename'];

        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.target = "_blank";
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.log(error));
  };
};

export const descargar_prestaciones_pdf = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/marcaciones/prestaciones-pdf`, data, {
      responseType: "arraybuffer"
    })
      .then(response => {

        let filename = response.headers['x-filename'];

        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.target = "_blank";
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.log(error));
  };
};

export const marcacion_remota = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/marcacion/crear`, data)
      .then(response => {
        dispatch(crear_marcacion_remota({status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const limpiarMarcacionesPorDependencia = () => {
  return function(dispatch) {
    dispatch(limpiar_marcaciones());
  };
};

export const limpiarPrestaciones = () => {
  return function(dispatch) {
    dispatch(limpiar_prestaciones());
  };
};

export const restaurarMensajes = () => {
  return function(dispatch) {
    dispatch(restaurar_mensajes());
  };
};

export const restaurarDependencias = () => {
  return function(dispatch) {
    dispatch(restaurar_dependencias());
  };
};
