import React from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

// MUI componentes
import { 
  Box,
  Grid,
  Typography,
} from '@mui/material';

// MUI iconos
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeWorkIcon from '@mui/icons-material/HomeWork';


const Dependencia = ({ nombre, id, imagen, oficina, seleccionada, onClick}) => {
  const titulo = nombre?.length > 70 ? nombre?.split(' ').splice(0, 5).join(' ') + ' (...)' : nombre;

  const handleDependencia = () => {
    onClick();
  };

  return (
    <Grid container xs={12} sm={11} md={12} lg={12}
    sx={{
      alignItems: 'center',
      justifyContent: 'center',
      
    }}>
    <Box  
      sx={{
        width: '26em',
        minHeight: '5em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '.5em',
        borderRadius: '.5em',
        padding: ".5em",
        boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
        fontWeight: seleccionada ? "bold" : "normal",
        bgcolor: seleccionada ? "primary.light" : 'background.paper',
        color: seleccionada ? "white" : "black",
        "&:hover": {
          cursor: 'pointer',
          transform: 'scale(1.01)',
        }
        
      }}
      onClick={handleDependencia}
    >
      {
        imagen ? imagen : oficina ? <HomeWorkIcon sx={{ fontSize:"2.5em", color:"primary.light" }}/> :<ApartmentIcon sx={{ fontSize:"2.5em", color: seleccionada?"white":"primary.light" }} />
      }
      <Box component="div" 
        sx={{
          width: "100%",
          display: "flex",
          flexDirection:"column",
          marginLeft: ".5em"
        }}
      >
        <Box component="div">
          <Typography variant="p" fontSize="1.1em" >
            {titulo}
          </Typography>
        </Box>
      </Box>
    </Box>
    </Grid>
  );
};

export default Dependencia;