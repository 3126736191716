import { obtenerFecha } from "./obtenerFecha";
import { horasTrabajadas } from "./horasTrabajadas";
import { obtenerHorario } from "./obtenerHorario";
import { obtenerDia } from "./obtenerDia";

// retorna un arreglo con las marcaciones de entrada y la de salida
export const obtenerHorariosEntradaSalida = (marcaciones, horas_establecidas) => {
  // recibo el arreglo con las marcaciones del usuario
  const resultado = [];
  // result deberia retornar el arreglo pero diferenciando la marcacion de entrada con la de salida
  // por lo tanto deberia tener aproximadamente la mitad del tamaño que el array marca
  let fechaEntrada, fechaSalida = null;
  for(let i = 0; i < marcaciones.length; i++) {
    // comienzo a recorrer el array marca
    // la idea es ir comparando las fechas
    fechaEntrada = new Date(marcaciones[i].fecha_marcacion);
    // guardo la fecha actual
    let indice = 1;
    // guardo el indice de la fecha siguiente
    while(indice + i <= marcaciones.length - 1 && obtenerFecha(fechaEntrada).fecha === obtenerFecha(new Date(marcaciones[i + indice].fecha_marcacion)).fecha) {
    // mientras que sea la misma fecha y no se caiga del arreglo --> ESTO HAY QUE CAMBIARLO PARA LOS SERENOS
      let fecha2 = new Date(marcaciones[i + indice].fecha_marcacion);
      // guardo la fecha de salida 
      if(obtenerFecha(fecha2).hora - obtenerFecha(fechaEntrada).hora <= horas_establecidas) {
        fechaSalida = fecha2;
        resultado.push(
          {
            fecha_marcacion: obtenerFecha(marcaciones[i].fecha_marcacion).fecha,
            entrada: obtenerFecha(fechaEntrada).horario.slice(0, -3), 
            salida: obtenerFecha(fechaSalida).horario.slice(0, -3),
            horas: horasTrabajadas(fechaEntrada, fechaSalida),
            justificacion: marcaciones[i].justificacion,
          },  
        ); 
      };
      indice++;
      // aumento el indice
    };
  };
  return resultado;
};

export const filtrarMarcaciones = (marcaciones, usuario_horario, horarioEntrada, horarioSalida, cantidadHoras) => {
  const marcacionesFiltradas = [];

  const gruposMarcaciones = agruparPorFecha(marcaciones);
  
  for(const grupo of gruposMarcaciones) {
    const marcacionOptima = obtenerMarcacionOptima(grupo, usuario_horario, horarioEntrada, horarioSalida, cantidadHoras);
    marcacionesFiltradas.push(marcacionOptima);
  };
  
  return marcacionesFiltradas;
};

export const agruparPorFecha = (marcaciones) => {
  const grupos = {};

  for(const marcacion of marcaciones) {
    const fecha = marcacion.fecha_marcacion;
    if(!grupos[fecha]) {
      grupos[fecha] = [];
    };
    grupos[fecha].push(marcacion);
  };
  
  return Object.values(grupos);
};

export const obtenerMarcacionOptima = (marcaciones, usuario_horario, horarioEntrada, horarioSalida, cantidadHoras) => {
  let mejorMarcacion = null;
  let mejorDiferencia = Number.MAX_VALUE;
  
  for(const marcacion of marcaciones) {
    const dia = obtenerDia(marcacion.fecha_marcacion);
    const horario = obtenerHorario(dia, usuario_horario);
    const entrada = obtenerHoraEnMinutos(marcacion.entrada);
    const salida = obtenerHoraEnMinutos(marcacion.salida);
    const horasTrabajadas = obtenerHoraEnMinutos(marcacion.horas);
    
    const diferenciaEntrada = Math.abs(entrada - horarioEntrada);
    const diferenciaSalida = Math.abs(salida - horarioSalida);
    const diferenciaHoras = Math.abs(horasTrabajadas - cantidadHoras);
    
    if((diferenciaEntrada <= 30 || diferenciaEntrada >= 30) && (diferenciaSalida <= 30 || diferenciaSalida >= 30) && diferenciaHoras < mejorDiferencia) {
      mejorMarcacion = marcacion;
      mejorDiferencia = diferenciaHoras;
    };
  };
  
  return mejorMarcacion;
};

export const obtenerHoraEnMinutos = (hora) => {
  const partesHora = hora.split(':');
  const horas = parseInt(partesHora[0], 10);
  const minutos = parseInt(partesHora[1], 10);
  return horas * 60 + minutos;
};


export const formatTime = (time) => {
  const [hh, mm] = time.split(':'); // Divide el string en horas y minutos

  if (hh === "00") {
    return `${mm} min`;
  } else {
    return `${hh}:${mm} hs`;
  }
}