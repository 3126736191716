import React from 'react';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import Slider from './Slider/Slider';
import Window from './Window';
import Pie from '../Pie/Pie';

// MUI componentes
import { 
  Grid,
} from '@mui/material';
import SpecialWindow from './SpecialWindow';

const Dashboard = () => {
  return (
    <>
      <Rotulo titulo={"Dashboard"} />
      <Grid container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        <Grid item container xs={12} sm={10} md={10} lg={8} mb={3}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Slider />
        </Grid>
        <Grid item container xs={12} sm={10} md={10} lg={8} mb={4}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Window titulo={"Relojes"} route={"relojes"}/>
          <Window titulo={"Roles"} route={"roles"}/>
          <Window titulo={"Cargos"} route={"administrar-cargos"}/>
          <Window titulo={"Auditoria"} route={"buscar-usuario-auditoria"}/>
          <Window titulo={"Usuarios sin vacaciones"} route={"usuarios-sin-vacaciones"} />
          <Window titulo={"Prestaciones"} route={"prestaciones/buscar-oficina"} />
          <SpecialWindow titulo={"Human Cron"} />
        </Grid>
      </Grid>
      <Pie />
    </>
  );
};

export default Dashboard;