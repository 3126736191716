import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useModal } from '../../../hooks/useModal';
// // import { useDecrypt } from '../../hooks/useDecrypt';

// Componentes
import Rotulo from '../../Rotulo/Rotulo';
import Dependencia from '../../Dependencias/Dependencia';
import InputMonth from './InputMonth';
import ModalComponent from '../../ModalComponent/ModalComponent';
import Pie from '../../Pie/Pie';
import TablaAuxiliar from './Tablas/TablaAuxiliar';
import HuellaUnica from './Tablas/HuellaUnica';
import Incumplen from './Tablas/Incumplen';

// Actions
import { buscar_por_id } from '../../../redux/action-types/dependenciaActions';
import { descargar_prestaciones_pdf, descargar_prestaciones_xlsx, limpiarPrestaciones, prestaciones } from '../../../redux/action-types/marcacionesActions';

// MUI componentes
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Tab,
  Typography,
  useMediaQuery,
} from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

const Informes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const matches = useMediaQuery('(min-width:650px)');
  
  const { id, mes, ano } = useParams();
  const { dependencia } = useSelector(state => state.dependencia);
  const { tardanzas, huella_unica, ausentes_por_tardanza, ingresos_anticipados, carga_horaria_incumplida, mensaje } = useSelector(state => state.marcaciones);

  const permisos = window.localStorage.getItem("permisos");
  const tipo_rol = window.localStorage.getItem("rol");

  const dniActual = window.localStorage.getItem("dni");

  const [ isProcessing, setIsProcessing ] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [ timeExceeded, setTimeExceeded ] = useState(false);
  const [ datosObtenidos, setDatosObtenidos ] = useState(false);

  const [ isOpenModal, openModal, closeModal ] = useModal();

  const [ detail, setDetail ] = useState([])
  const [ tab, setTab ] = useState("1");
  const [ message, setMessage] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleClick = () => {
    const urlAnterior = window.sessionStorage.getItem('urlAnterior');  
    navigate(urlAnterior);
  };

  const handlePerfil = (dni) => {
    window.sessionStorage.setItem("urlAnterior", `/informe-prestaciones/${id}/${selectedMonth}/${selectedYear}`);
    navigate(`/perfil/${dni}`);
  };

  const handleDownload = (type) => {    
    setIsProcessing(true);
    openModal();
    setAnchorEl(null);
    const data = {
      dependencia: dependencia?.name, 
      mes: selectedMonth, 
      anio: selectedYear,
      tardanzas: tardanzas,
      huella_unica: huella_unica, 
      ausentes_por_tardanza: ausentes_por_tardanza, 
      ingresos_anticipados: ingresos_anticipados, 
      carga_horaria_incumplida: carga_horaria_incumplida
      };
    if(type == 'xlsx'){
      dispatch(descargar_prestaciones_xlsx(data))
      .then(() => {
        setIsProcessing(false);
        closeModal();
      });
    }
    else if(type == 'pdf'){
      dispatch(descargar_prestaciones_pdf(data))
      .then(() => {
        setIsProcessing(false);
        closeModal();
      });
    }
    
  };

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(limpiarPrestaciones());
    setDatosObtenidos(false);
    const data = {dependencia: id, mes: selectedMonth, anio: selectedYear};
    dispatch(prestaciones(data))
    .then(() => {
      setDatosObtenidos(true);
    })
    .catch((error) => {
      console.error("Error fetching prestaciones:", error);
      setDatosObtenidos(false); 
    });
  };

  useEffect(() => {
    const data = {dependencia: id, mes: mes, anio: ano};
    dispatch(prestaciones(data))
    .then(() => {
      setDatosObtenidos(true);
    })
    .catch((error) => {
      console.error("Error fetching prestaciones:", error);
      setDatosObtenidos(false); 
    });
  }, [dispatch, id, mes, ano]);

  useEffect(() => {
    dispatch(limpiarPrestaciones());
    setDatosObtenidos(false);
    setSelectedMonth(mes);
    setSelectedYear(ano);
    dispatch(buscar_por_id(id));
  }, [dispatch, id, mes, ano]);

  
  // Si tiene autorizacion
  return (
    <>
      <Rotulo titulo={"Marcaciones"} />
      <Grid container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        <Grid item xs={11} sm={11} md={10} lg={8}>
          <Button
            variant="contained"
            sx={{
              alignSelf: "start",
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
            onClick={handleClick}
          >
            Buscar
          </Button>
          <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
        </Grid>
        <Grid item container xs={12} sm={10} md={10} lg={8}
          sx={{
            display:"flex",
            justifyContent: "center",
          }}
        >
          <Grid item xs={10} sm={10} md={5} lg={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Componente que muestra los datos del usuario */}
            <Dependencia
                key={dependencia?.id}
                id={dependencia?.id}
                nombre={dependencia?.name}
            />
          </Grid>
          <Grid item xs={10} sm={10} md={7} lg={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "space-evenly",
            }}
          >
            <InputMonth 
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                handleMonthChange={handleMonthChange}
                handleYearChange={handleYearChange}
                handleSubmit={handleSubmit}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                handleDownload={handleDownload}
            />
          </Grid>
        </Grid>

        <Grid item mt={2} xs={12} sm={11} md={10} lg={8} marginLeft={".8em"} marginRight={".8em"}>
          <Divider />
        </Grid>
        
        <Grid item xs={12} sm={11} md={10} lg={8}
            sx={{
            display:"flex",
            justifyContent: "center",
            flexDirection: "column"
            }}
        >
            <Grid item container>
              <TabContext value={tab}>
                <Grid item container
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    display:"flex",
                    justifyContent: "center",
                }}
                >
                  <TabList 
                    onChange={handleTab} 
                    aria-label="tabs"
                    variant="scrollable" // Permite el desplazamiento horizontal
                    scrollButtons="auto" // Muestra botones de desplazamiento si es necesario
                    allowScrollButtonsMobile>
                    <Tab label="Tardanzas" value={"1"} sx={{ fontSize:".8em", fontWeight:"bold", color: "text.default" }} />
                    <Tab label="Ausentes por tardanza" value={"2"} sx={{ fontSize:".8em", fontWeight:"bold", color: "text.default" }} />
                    <Tab label="Ingresos anticipados" value={"3"} sx={{ fontSize:".8em", fontWeight:"bold", color: "text.default" }} />
                    <Tab label="Huella única" value={"4"} sx={{ fontSize:".8em", fontWeight:"bold", color: "text.default" }} />
                    <Tab label="Incumplen horario" value={"5"} sx={{ fontSize:".8em", fontWeight:"bold", color: "text.default" }} />                                        
                    <Tab label="Fuera de termino" value={"6"} sx={{ fontSize:".8em", fontWeight:"bold", color: "text.default" }} />
                  </TabList>
                </Grid>
              </TabContext>
            </Grid>

                <Box 
                    sx={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        margin: "10px"
                    }}
                >
                  {!datosObtenidos ?
                  <CircularProgress
                  />
                  :
                     tab == "1" ?
                        <TablaAuxiliar
                          datos={tardanzas}
                          mensaje={"*Se considera tardanza a la entrada entre 15 y 45 minutos después del horario asignado"}
                          handlePerfil={handlePerfil} />
                        : tab == "2" ?
                        <TablaAuxiliar
                              datos={ausentes_por_tardanza}
                              mensaje={"*Se considera ausente por tardanza a la entrada 45 minutos después del horario asignado"} 
                              handlePerfil={handlePerfil}/>                        
                        : tab == "3" ?
                        <TablaAuxiliar
                          datos={ingresos_anticipados}
                          mensaje={"*Se considera ingreso anticipado a la entrada más de 15 minutos antes del horario asignado"}
                          handlePerfil={handlePerfil} />                                
                        : tab == "4" ?
                        <HuellaUnica
                          datos={huella_unica}
                          mensaje={"*Se considera entrada única a aquellos días que solo tienen una marcación"} 
                          handlePerfil={handlePerfil}/>                                                     
                        : tab == "5" ?
                        <Incumplen
                          datos={carga_horaria_incumplida}
                          mensaje={"*Se considera semana desde el lunes hasta el domingo, excepto por la primera, que es desde el inicio del mes, y la última, que es hasta el último día del mes"} 
                          handlePerfil={handlePerfil}/> 
                        : 
                        null
                            }
                    
                </Box>

                

              
          </Grid>
        

        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          {message
          ?
          <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
          {message}
          </Typography>
          :
          mensaje
          ?
          <>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Redireccionando...
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {mensaje}
          </Typography>
          </>
          :
          <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
          Ingrese una fecha de inicio y una de fin para el reporte.
          </Typography>}
        </ModalComponent>
      </Grid>
      {/* Componente de Pie Pag */}
      <Pie />
    </>
  );
};

export default Informes;