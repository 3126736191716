import React from 'react';

// MUI componentes
import {
    Box,
  Divider, 
  Grid,
  Paper,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

const Incumplen = ({datos, mensaje, handlePerfil}) => {

  const matches = useMediaQuery('(min-width:650px)');
  if(datos) {
    return (
      <>

          <Grid item container lg={10} md={10} sm={10} xs={10} >
            
          <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow key={"0"} sx={{bgcolor:"primary.light"}}>
                  <TableCell key={"dni"} align="center">DNI</TableCell>
                  <TableCell key={"name"} align="center">Nombre</TableCell>
                  <TableCell key={"cargo"} align="center">Cargo</TableCell>
                  <TableCell key={"days"} align="center">Semanas con carga incumplida</TableCell>
                </TableRow>
              </TableHead>
              {
                <TableBody>
                  {
                    datos && Object.keys(datos).map(key  => {               
                      return (
                        <TableRow
                          key={key}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}                          
                        >
                            <TableCell 
                              align="center"
                              sx={{
                                "&:hover": {
                                cursor: "pointer",
                                borderRadius: ".5em",
                                boxShadow:
                                    "1px 1px 3px 3px rgba(40,40,40,0.4)",
                                },
                            }}
                              onClick={() =>
                                handlePerfil(key)} >{key}</TableCell>
                            <TableCell align="center" >{datos[key][0]}</TableCell> 
                            <TableCell align="center" >{datos[key][1]}</TableCell> 
                            <Tooltip
                            title={
                                <Table sx={{ minWidth: 200, bgcolor: "background.paper" }} size="small">
                                <TableHead>
                                    <TableRow key={"1"} sx={{bgcolor:"primary.light"}}>
                                    <TableCell>Semana</TableCell>
                                    <TableCell>Horas esperadas</TableCell>
                                    <TableCell>Horas trabajadas</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(datos[key][2] || {}).map(([semana, horas], index) => (
                                        <TableRow key={index}>
                                            <TableCell>{semana}ª</TableCell>
                                            <TableCell>{horas[0]}</TableCell>
                                            <TableCell>{horas[1]}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                </Table>
                                }
                                arrow
                                placement="right" enterDelay={600} leaveDelay={100}
                                >                     
                                <TableCell align="center"
                                sx={{
                                    "&:hover": {
                                    cursor: "pointer",
                                    borderRadius: ".5em",
                                    boxShadow:
                                        "1px 1px 3px 3px rgba(40,40,40,0.4)",
                                    },
                                }}>                          
                                    {Object.keys(datos[key][2] || {})
                                    .map(semana => `${semana}º`)
                                    .join(' - ')}
                                </TableCell>   
                            </Tooltip>     
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              }
            </Table>
          </TableContainer>
          <Box
            sx={{
                marginTop: "5px"
            }}>
            <Typography variant='h7'>*{mensaje}</Typography>
            </Box>
      </Grid>
        
      </>
    )
  };
    
 
};

export default Incumplen;