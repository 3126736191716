import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import { useModal } from '../../../hooks/useModal';

// Componentes
import Dependencia from './Dependencia';
import Rotulo from '../../Rotulo/Rotulo';
import Pie from '../../Pie/Pie';
import ModalComponent from '../../ModalComponent/ModalComponent';

// Acciones
import { buscar_cabeceras, buscar_dependencias_hijas_con_padre, restaurarHijas } from '../../../redux/action-types/dependenciaActions';

// MUI Componentes
import { 
  Box,
  Button,
  Container,
  FormControl,  
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


// MUI iconos
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'primary.light',
    color: 'white',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const BuscarOficina = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const matches = useMediaQuery('(min-width:500px)');
  
  const { cabeceras, hijas, padre } = useSelector(state => state.dependencia);
  const [isOpenModal, openModal, closeModal] = useModal();

  const currentMonth = new Date().getMonth(); // getMonth() devuelve un valor de 0 a 11, por lo que se suma 1
  const currentYear = new Date().getFullYear();

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [ultimaSeleccionada, setUltimaSeleccionada] = useState(null);
  const [idReporte, setIdReporte] = useState(null);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const months = [
    { value: 1, label: 'Enero' },
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' },
  ];

  const years = [];
  for (let year = currentYear; year >= 2016; year--) {
    years.push(year);
  }

  const handleClick = () => {
    window.sessionStorage.setItem("urlAnterior", `/prestaciones/buscar-oficina`);
    navigate(`/informe-prestaciones/${idReporte}/${selectedMonth}/${selectedYear}`); 
  };

  const handleCancel = () => {
    setIdReporte(null);
    closeModal();
  }

  const handleSeleccion = (index, id) => {
    setUltimaSeleccionada(index);
    dispatch(buscar_dependencias_hijas_con_padre(id));
  };

  const handleOficina = (id) => {
    dispatch(buscar_dependencias_hijas_con_padre(id));
  }

  const handleReporte = (id) => {
    setIdReporte(id);
    openModal();
  }

  useEffect(() => {
    dispatch(buscar_cabeceras());
    dispatch(restaurarHijas());
  }, [dispatch]);
  
  return (
    <>
      <Rotulo titulo={"Seleccionar Oficina"} />
      {/* Contenedor del componente */}
      <Container maxWidth="full" 
        sx={{ 
          width: '100%',
          height: '100%', 
          display:"flex",
          justifyContent: 'center',
          marginBottom: '5em',
          marginTop: '3em',
        }}
      >
      <Grid component="main" container spacing={4}
          
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifySelf: 'center',
            alignItems: 'center',        
            margin: {xs:"auto", sm:0},    
          }}
        >
          <Grid  xs={12} sm={6} md={6} lg={6} spacing={4}
            sx={{
              width: '50%',
              height: {xs:'auto', sm:"100%"},
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              order: { xs: 1, sm: 1 },
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '4em',
              }}
            >
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '1em',
              }}>
                <ApartmentIcon></ApartmentIcon>
                <Typography component={"h3"} fontSize={'20px'} marginLeft={"5px"} fontWeight={"bold"}>
                    Dependencias:
                </Typography>
              </Box>
              {/* Renderizado de resultados */}
              {
                cabeceras && cabeceras.map((dependencia, index) => {
                  if (dependencia.name === "UNLP"){
                    return null
                  }
                  return (
                    <Dependencia 
                      key={dependencia.id}
                      id={dependencia.id}
                      nombre={dependencia.name}
                      oficina={false}
                      onClick={() => handleSeleccion(index, dependencia.id)}
                      seleccionada={ultimaSeleccionada === index}
                    />
                  )
                })
              }
            </Box>
          </Grid>
          {/* Search Input */}
          <Grid xs={12} sm={6} md={6} lg={6} spacing={4}
            sx={{
              width: '50%',
              height: {xs:'auto', sm:"100%"},
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: {xs:"auto", sm:0},
              order: { xs: 2, sm: 2 },
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '4em',
              }}
            >
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '1em',
              }}>
                <HomeWorkIcon></HomeWorkIcon>
                <Typography component={"h3"} fontSize={'20px'} marginLeft={"5px"} fontWeight={"bold"}>
                    {padre ? `Oficinas de ${padre.name}` : "Oficinas"}
                </Typography>
              </Box>
              {/* <SearchInput handleChange={handleChange} handleClick={handleClick} placeholder={"Ingrese el lugar"} /> */}
            
            {/* Renderizado de resultados */}
            {padre ?
            <Dependencia 
              key={padre.id}
              id={padre.id}
              nombre={padre.name}
              oficina={false}
              onClick={() => handleReporte(padre.id)}
            />              
            :
            <Typography>Seleccione una dependencia para ver sus oficinas</Typography>}
            {hijas ?
                hijas && hijas.map(dependencia => {    
                  if (!matches) {
                  return(
                  <Dependencia 
                    key={dependencia.id}
                    id={dependencia.id}
                    nombre={dependencia.name}
                    oficina={true} 
                  />)
                  }
                  else{
                    return (
                      <HtmlTooltip placement="left" arrow title={
                        <React.Fragment>
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}>
                        <Button onClick={() => handleOficina(dependencia.id)}
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          justifyContent: "center",
                          alignContent: "center"
                        }}>
                          Buscar suboficinas
                          </Button>            
                          </Box>
                        </React.Fragment>}
                        >
                      <div width="auto">
                      <Dependencia 
                        key={dependencia.id}
                        id={dependencia.id}
                        nombre={dependencia.name}
                        onClick={() => handleReporte(dependencia.id)}
                        oficina={true} 
                      />
                      </div>
                      </HtmlTooltip>
                    )
                  }                  
                })
              :
              null
              }
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* Modal */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Grid id={"editar_vacacion"} container >
        <Typography sx={{marginBottom: "0.5em", width:"100%"}} id="modal-modal-title" variant="h6" component="h2">
          Reporte de prestaciones
        </Typography>

        <Box sx={{marginBottom: "0.5em", width:"100%"}}>          
          <Typography id="modal-modal-description" component="h2" sx={{marginBottom:"0.5em" }}>
              Ingrese el mes y el año del reporte que desea realizar
          </Typography> 
        <Box sx={{ display: 'flex', width: "100%", justifyContent:"space-around",gap: '1em' }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="month-select-label" sx={{ color: 'black' }}>Mes</InputLabel>
          <Select
            labelId="month-select-label"
            value={selectedMonth}
            label="Mes"
            onChange={handleMonthChange}
          >
            {months.map((month) => (
              <MenuItem key={month.value} value={month.value}>
                {month.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="year-select-label" sx={{ color: 'black' }}>Año</InputLabel>
          <Select
            labelId="year-select-label"
            value={selectedYear}
            label="Año"
            onChange={handleYearChange}
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>           
      </Box>
      
        <Box sx={{ display: 'flex', width: "100%", justifyContent:"space-around", marginTop:"1em",gap: '1em' }}>
          <Button
            variant="contained"
            onClick={handleCancel}
            sx={{ 
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
          >
            Cancelar
          </Button>
          <Button  
            onClick={handleClick}
            variant="contained"
            sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
                }
            }}>
                Generar                
            </Button>
          </Box>        
        </Grid>
      </ ModalComponent>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default BuscarOficina;
