// Obtiene el horario que corresponde segun el dia de la semana

export const obtenerHorario = (dia, horario) => {
  if (horario) {
    for(let i = 0; i < horario.length; i++) {
      if(horario[i].dia === dia) {
        const entrada = horario[i].entrada.slice(0, -3) === '00:00' ? '' : `${horario[i].entrada.slice(0, -3)} - `;
        const salida = horario[i].salida.slice(0, -3) === '00:00' ? '' : horario[i].salida.slice(0, -3);
        return `${entrada} ${salida}`;
      };
    };
  };
  return null;
};

export const obtenerHorarioMasCercano = (horarios, fechaObjetivo) => {
  // Convertir la fecha objetivo a un objeto Date si no lo es
  const [dia, mes, anio] = fechaObjetivo.split("/").map(Number);
  const fechaObj = new Date(anio, mes - 1, dia); // Mes es base 0 en JavaScript

  // Filtrar los horarios con fecha válida anterior a la fecha objetivo
  const horariosValidos = horarios?.filter(horario => {
      return new Date(horario.fecha_valido) < fechaObj;
  });

  // Si no hay horarios válidos, retornar un mensaje o valor predeterminado
  if (horariosValidos?.length === 0) {
      return "No hay horarios válidos";
  }

  // Encontrar el horario más cercano (máximo en fechas válidas)
  const horarioMasCercano = horariosValidos?.reduce((masCercano, horarioActual) => {
      const fechaActual = new Date(horarioActual.fecha_valido);
      const fechaMasCercana = new Date(masCercano.fecha_valido);
      return fechaActual > fechaMasCercana ? horarioActual : masCercano;
  });

  // Formatear y retornar el horario
  const entrada = horarioMasCercano?.entrada.slice(0, -3) === '00:00' ? '' : horarioMasCercano?.entrada.slice(0, -3);
  const salida = horarioMasCercano?.salida.slice(0, -3) === '00:00' ? '' : horarioMasCercano?.salida.slice(0, -3);
  return `${entrada} - ${salida}`;
}
