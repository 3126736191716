import React from 'react';

// MUI componentes
import {
    Box,
  Divider, 
  Grid,
  Paper,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

const HuellaUnica = ({datos, mensaje, handlePerfil}) => {

  const matches = useMediaQuery('(min-width:650px)');

  if(datos) {
    return (
      <>

          <Grid item container lg={10} md={10} sm={10} xs={10} >
            
          <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow key={"0"} sx={{bgcolor:"primary.light"}}>
                  <TableCell key={"dni"} align="center">DNI</TableCell>
                  <TableCell key={"name"} align="center">Nombre</TableCell>
                  <TableCell key={"cargo"} align="center">Cargo</TableCell>
                  <TableCell key={"days"} align="center">Cantidad de días</TableCell>
                  <TableCell key={"days_moth"} align="center">Días del mes</TableCell>
                </TableRow>
              </TableHead>
              {
                <TableBody>
                  {
                    datos && Object.keys(datos).map(key  => {               
                      return (
                        <TableRow
                          key={key}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}                          
                        >
                            <TableCell 
                              align="center"
                              sx={{
                                "&:hover": {
                                cursor: "pointer",
                                borderRadius: ".5em",
                                boxShadow:
                                    "1px 1px 3px 3px rgba(40,40,40,0.4)",
                                },
                            }}
                              onClick={() =>
                                handlePerfil(key)} >{key}</TableCell>
                            <TableCell align="center" >{datos[key][0]}</TableCell>   
                            <TableCell align="center" >{datos[key][1]}</TableCell>                  
                            <TableCell align="center">                          
                                {datos[key][2].length}
                            </TableCell>
                            <TableCell align='center'>
                                {datos[key][2].map(dia => `${dia}º`)
                                .join(' - ')}
                            </TableCell>           
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              }
            </Table>
          </TableContainer>
          <Box
            sx={{
                marginTop: "5px"
            }}>
            <Typography variant='h7'>*{mensaje}</Typography>
            </Box>
      </Grid>
        
      </>
    )
  };
    
  
};

export default HuellaUnica;