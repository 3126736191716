import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  marcaciones_rango: [],
};

export const marcacionesSlice = createSlice({
  name: 'marcacionsReducer',
  initialState,
  reducers: {
    marcaciones_por_dni: (state, { payload }) => {
      return {
        ...state,
        marcaciones_rango: payload.data,
        usuario_horario: payload.usuario_horario,
        sereno: payload.sereno,
        mensaje: payload.text,
        status: payload.status
      };
    },
    marcaciones_x_dependencia: (state, { payload }) => {
      return {
        ...state,
        marcaciones_dependencia: payload.marcaciones,
        horarios: payload.horarios,
        resumen: payload.resumen,
        has_next: payload.has_next,
        has_prev: payload.has_prev,
        total: payload.total,
        status: payload.status
      };
    },
    detalle_marcaciones_rango: (state, { payload }) => {
      return {
        ...state,
        detalle: payload.detalle
      };
    },
    prestaciones_red: (state, { payload }) => {
      return {
        ...state,
        tardanzas: payload.tardanzas,
        huella_unica: payload.huella_unica,
        ausentes_por_tardanza: payload.ausentes_por_tardanza,
        ingresos_anticipados: payload.ingresos_anticipados,
        carga_horaria_incumplida: payload.carga_horaria_incumplida
      };
    },
    crear_marcacion_remota: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        mensaje: payload.text,
      };
    },
    restaurar_mensajes: (state) => {
      return {
        ...state,
        marcaciones_rango: [],
        status: '',
        mensaje: '',
      };
    },
    restaurar_dependencias: (state) => {
      return {
        ...state,
        marcaciones_dependencia: [],
        resumen: [],
      };
    },
    limpiar_marcaciones: (state) => {
      return {
        ...state,
        marcaciones_dependencia: [],
        resumen: [],
        status: ''
      };
    },
    limpiar_prestaciones: (state) => {
      return {
        ...state,
        tardanzas: [],
        huella_unica: [],
        ausentes_por_tardanza: [],
        ingresos_anticipados: [],
        carga_horaria_incumplida: []
      };
    },
  },
});

export const { marcaciones_por_dni, marcaciones_x_dependencia, detalle_marcaciones_rango, prestaciones_red, marcaciones_rango, crear_marcacion_remota, restaurar_mensajes, restaurar_dependencias, limpiar_marcaciones, limpiar_prestaciones } = marcacionesSlice.actions;

export default marcacionesSlice.reducer;
