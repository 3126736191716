import React from 'react';

import { styled, alpha } from '@mui/material/styles';

// MUI Componentes
import {
  Button,
  Box,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 140,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const currentMonth = new Date().getMonth(); // getMonth() devuelve un valor de 0 a 11, por lo que se suma 1
const currentYear = new Date().getFullYear();

const months = [
    { value: 1, label: 'Enero' },
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' },
  ];

const years = [];
  for (let year = currentYear; year >= 2016; year--) {
    years.push(year);
  };


const InputMonth = ({ selectedYear, selectedMonth, handleMonthChange, handleYearChange, handleSubmit, handleDownload, anchorEl, setAnchorEl }) => {

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Grid container component="form" noValidate onSubmit={handleSubmit}
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
          alignItems: "center",
          gap: 1
        }}
      >
        <Grid item xs={10} sm={8} md={8} lg={6} p={1}
          sx={{
            display: "flex",
            width: "60%",
            justifyContent: "space-evenly",
            gap:1.5,
          }}
        > 
          <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="month-select-label" sx={{ color: 'black' }} shrink={selectedMonth !== ""}>Mes</InputLabel>
          <Select
            labelId="month-select-label"
            value={selectedMonth || currentMonth}
            label="Mes"
            onChange={handleMonthChange}
          >
            {months.map((month) => (
              <MenuItem key={month.value} value={month.value}>
                {month.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="year-select-label" sx={{ color: 'black' }} shrink={selectedYear !== ""}>Año</InputLabel>
          <Select
            labelId="year-select-label"
            value={selectedYear || currentYear}
            label="Año"
            onChange={handleYearChange}
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </Grid>

        <Grid item xs={8} sm={8} md={8} lg={4} p={1}
          sx={{
            display: "flex",
            width: "30%",
            justifyContent: "space-evenly",         
            gap:1,
          }}
        >         
          <Box item xs={12} sm={6} md={4} lg={2}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 100,
                
            }}
            >   
            <Button
                type="submit"
                variant="contained"
                sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                }
                }}
            >
                Generar
            </Button> 
            </Box>
            <Box item xs={12} sm={6} md={4} lg={2}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 100,
            }}
            >
            <Button        
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant="contained"
                sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                }
                }}
            >
            <FileDownloadIcon aria-label="Download" color="primary" />
            </Button>
            <StyledMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            >        
            <MenuItem onClick={() => handleDownload('xlsx')}>
                <ListItemText>Excel</ListItemText>
                <DescriptionIcon color="success"></DescriptionIcon>
            </MenuItem>
            <MenuItem onClick={() => handleDownload('pdf')}>
                <ListItemText>PDF</ListItemText>
                <PictureAsPdfIcon color="error" aria-label="PDF" ></PictureAsPdfIcon>
            </MenuItem>        
            </StyledMenu>
        
            </Box>
        </Grid>
      </Grid>
    );
  };
  
  export default InputMonth;